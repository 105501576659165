const coursePageReady = () => {
  $('.payment-scroller').on('click', () => {
    scrollToPayments()
  })

  hideNonVatInclusivePrices()

  getParameterData()

  overrideHeaderSignup()

  signupScrollBottomIfNeeded()

  if ($('.certificate-container')[0]) {
    setupCertificatePage()
  }

  hideVatSubscriptionDisclaimer()

  return false
}

const scrollToPayments = () => {
  if ($('#pricing-options')) {
    return $('html,body').animate(
      { scrollTop: (window as any).$('#pricing-options').offset().top - 130 },
      'slow'
    )
  }

  return undefined
}

const getParameterData = () => {
  if (shouldGetCouponOrProductData()) {
    $.ajax({
      url: couponCheckerPath().toString(),
      data: (window as any).getQueryString(),
      method: 'GET',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
    }).then((window as any).fillDataFromParameters)
  }

  if (
    coursePageVatTaxableCountry() &&
    currentUrl().path.indexOf('/purchased') === -1
  ) {
    return $.ajax({
      url: vatCheckerPath().toString(),
      data: (window as any).getQueryString(),
      method: 'GET',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
    })
      .then(setVatPricesForProducts)
      .fail(setPricesWithoutVatForProducts)
  }

  return undefined
}

const hideNonVatInclusivePrices = () => {
  if (coursePageVatTaxableCountry()) {
    if (courseUrl()) {
      return $('.default-product-price').addClass('hidden')
    }
    return $('div[data-include-vat-price]').addClass('hidden')
  }

  return undefined
}

let currentUrl = function () {
  const urlObj = {
    href: window.location.href,
    host: window.location.host,
    path: window.location.pathname,
    params: {},
  }
  window.location.search
    .toLowerCase()
    .replace(/^\?/, '')
    .split('&')
    .forEach(
      (query) => (urlObj.params[query.split('=')[0]] = query.split('=')[1])
    )
  return urlObj
}

const isSecurePage = () => {
  if (
    currentUrl().path.indexOf('/purchased') > -1 ||
    currentUrl().path.indexOf('/sign_in') > -1
  ) {
    return true
  }

  return undefined
}

const urlHasCouponOrProductParams = () => {
  if (
    (currentUrl().params as any).coupon ||
    (currentUrl().params as any).code ||
    (currentUrl().params as any).couponcode ||
    (currentUrl().params as any).coupon_code ||
    (currentUrl().params as any).product_id
  ) {
    return true
  }

  return undefined
}

let shouldGetCouponOrProductData = function () {
  if (
    !isSecurePage() &&
    (courseUrl() || currentUrl().path.indexOf('/blog') > -1) &&
    urlHasCouponOrProductParams()
  ) {
    return true
  }
  return false
}

const coursePageCoursePath = () => {
  const url = courseUrl()
  try {
    return new URL(url || window.location.toString()).pathname
  } catch {
    return url
  }
}

let courseUrl = () => $('meta[id=fedora-data]').attr('data-course-url')

let couponCheckerPath = () =>
  `${coursePageCoursePath()}/coupon_and_product_data`

let vatCheckerPath = function () {
  if (courseUrl()) {
    return `${coursePageCoursePath()}/vat_rates_for_course_products`
  }
  return `/api/v2/pricing_plans/vat_adjusted_prices${parameterizedProductIds()}`
}

let parameterizedProductIds = () => {
  const productIds = $('div[data-include-vat-price]')
    .toArray()
    .map((div) => div.dataset.includeVatPrice)
    .filter((id) => !!id)
  return `?${$.param({ product_ids: productIds })}`
}

let coursePageVatTaxableCountry = () =>
  $('meta[id=fedora-data]').attr('data-vat-country')

;(window as any).fillDataFromParameters = function (data) {
  fillCouponElements(data)
  return selectProduct(data.selected_product_id)
}

let fillCouponElements = function (data) {
  if (!data.coupon_param) {
    return undefined
  }
  $('input#coupon_code').val(data.coupon_code)

  if (data.discounted_products.length === 0) {
    // hide the entire discount message and show the unavailable message instead
    $('.coupon-discount-text').hide() // hide entire div
    $('.coupon-invalid-text').show().removeClass('hidden')
    $('.coupon_banner, .coupon-code-applied').show().removeClass('hidden')
  } else {
    for (const product of Array.from(data.discounted_products)) {
      if ((product as any).discount_type === 'percent') {
        // in the banner
        if ((product as any).product_id === data.selected_product_id) {
          $('.coupon-discount-text> .coupon-discount').text(
            `${data.discount_percent}%`
          )
          $('.coupon_banner, .coupon-code-applied').show().removeClass('hidden')
        }
        // in products list
        $(
          `.discount.product_${(product as any).product_id}> .coupon-discount`
        ).html(`${(product as any).discount_percent}%`)
      } else if ((product as any).discount_type === 'amount') {
        // in the banner
        if ((product as any).product_id === data.selected_product_id) {
          $('.coupon-discount-text> .coupon-discount').text(
            (product as any).formatted_discount
          )
          $('.coupon_banner, .coupon-code-applied').show().removeClass('hidden')
        }
        // in products list
        $(
          `.discount.product_${(product as any).product_id}> .coupon-discount`
        ).html((product as any).formatted_discount)
      }

      $(`.default-product-price.product_${(product as any).product_id}`).text(
        (product as any).original_price
      )
      $(
        `.default-product-price.product_${(product as any).product_id}`
      ).addClass('strike-through-price')

      $(`.coupon-price.product_${(product as any).product_id}`).text(
        (product as any).formatted_final_price
      )

      $(`.discount.product_${(product as any).product_id}`)
        .show()
        .removeClass('hidden')
    }
  }
}

let setVatPricesForProducts = function (data) {
  if (courseUrl()) {
    return (() => {
      for (const product of Array.from(data.vat_adjusted_products)) {
        $(`.default-product-price.product_${(product as any).product_id}`).text(
          (product as any).formatted_final_price
        )
        $('.default-product-price').removeClass('hidden')
      }
    })()
  }
  return (() => {
    for (const product of data.data) {
      const el = $(`div[data-include-vat-price="${product.id}"]`)
      if (el) {
        el.text(product.attributes.vat_adjusted_price)
      }
    }
    $('div[data-include-vat-price]').removeClass('hidden')
  })()
}

let setPricesWithoutVatForProducts = function () {
  if (courseUrl()) {
    return $('.default-product-price').removeClass('hidden')
  }
  return $('div[data-include-vat-price]').removeClass('hidden')
}

let selectProduct = function (product_id) {
  if (!product_id || !($(`input[id=product_id_${product_id}]`).length > 0)) {
    return undefined
  }
  $(`input[id=product_id_${product_id}]`).prop('checked', true)
  $('.checkout-button-group').removeClass('active')
  return $(`.checkout-button-group.product_${product_id}`).addClass('active')
}

// If they're on a course page, override header signup button to course sign up
let overrideHeaderSignup = () => {
  if ($('.btn-header-enroll').length) {
    return $('.navbar-nav .btn.btn-primary').click((e) => {
      e.preventDefault()
      return $('.btn-header-enroll').click()
    })
  }

  return undefined
}

// If they've tried to pre-enroll and are given a flash message, autoscroll to the bottom signup form
let signupScrollBottomIfNeeded = function () {
  if ($('.auto-scroll-on-page-load').length) {
    return scrollToPayments()
  }

  return undefined
}

let setupDownloadBar = () =>
  $('.download-button').click(function (evt) {
    $(this).text('Downloading...')
    return $(this).addClass('hidden')
  })

let showConfetti = function () {
  const queryString = (window as any).getQueryString()

  if (queryString.certificate_first_issued) {
    const config = {
      target: 'confetti-canvas',
      max: 800,
      size: 2,
      animate: true,
      props: ['square', 'triangle', 'line'],
      colors: [
        [165, 104, 246],
        [230, 61, 135],
        [0, 199, 228],
        [253, 214, 126],
      ],
      clock: 50,
    }

    // $(".course-block.block").css({
    //   position: "absolute"
    //   "z-index": 3
    //   background: "none"
    //   left: "50%"
    //   "margin-left": "-200px"
    // })

    const appendTo: any = $('.course-mainbar').css({ 'min-height': '900px' })

    const opacityMask = $('<div>')
      .addClass('confetti-mask')
      .height(appendTo.innerHeight())
      .width(appendTo.innerWidth())
      .appendTo(appendTo)
    // .fadeOut(7000)

    const canvas = $('<canvas>')
      .addClass('confetti-canvas')
      .attr('id', 'confetti-canvas')
      .height(appendTo.innerHeight())
      .width(appendTo.innerWidth())
      .appendTo(appendTo)

    setTimeout(() => {
      canvas.addClass('fader')

      return setTimeout(() => opacityMask.addClass('fader'), 2000)
    }, 1000)

    return (window as any).ConfettiGenerator(config).render()
  }
}

let setupCertificatePage = function () {
  setupDownloadBar()
  showConfetti()
}

const hideVatSubscriptionDisclaimer = () => {
  if (!coursePageVatTaxableCountry()) {
    return $('.vat-subscription-disclaimer').addClass('hidden')
  }

  return undefined
}

$(document).ready(coursePageReady)
$(document).on('page:load', coursePageReady)
