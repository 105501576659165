const lectureData = () => $('#lecture_heading').data()

const coursePath = () => location.pathname.split('lectures/')[0]

const setLoadingUrl = function (str) {
  const lectureCompleteButton = document.getElementById(
    'lecture_complete_button'
  )
  if (lectureCompleteButton) {
    lectureCompleteButton.dataset.loadingUrl = str
  }
}

const fetchLoadingUrl = () => {
  const lectureCompleteButton = document.getElementById(
    'lecture_complete_button'
  )
  if (lectureCompleteButton) {
    return lectureCompleteButton.dataset.loadingUrl
  }

  return undefined
}

const markAsComplete = function () {
  const user = (window as any).currentUser()
  const deferred = $.Deferred()
  if (fetchLoadingUrl() !== completionUrl()) {
    setLoadingUrl(completionUrl())
    if (user != null ? user.id : undefined) {
      markAsCompleteInSidebar()
      updateCompletion(percentComplete())
      $.ajax({
        url: completionUrl(),
        data: {},
        method: 'POST',
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
      }).done((data) => deferred.resolve(data))
    } else {
      deferred.resolve({ percent_complete: 0 })
    }

    ;(window as any).trackEvent('Curriculum: Lecture Complete', {
      lectureUrl:
        lectureShowGuard(
          typeof window !== 'undefined' && window !== null
            ? (window as any).lecture_data
            : undefined,
          (x) => x.lectureUrl
        ) || undefined,
      lectureId:
        lectureShowGuard(
          typeof window !== 'undefined' && window !== null
            ? (window as any).lecture_data
            : undefined,
          (x1) => x1.lectureId
        ) || undefined,
    })

    if (lectureShowGuard(percentComplete(), (x2) => x2.toString()) === '100') {
      ;(window as any).trackEvent('Curriculum: Course Complete', {
        courseId:
          lectureShowGuard(
            typeof window !== 'undefined' && window !== null
              ? (window as any).course_params
              : undefined,
            (x3) => x3.friendlyUrl
          ) || undefined,
      })
    }

    return deferred.promise()
  }
  return false
}

const getNextLectureUrl = function () {
  let item = $('li.next-lecture').next().data()
  if (item) {
    return item.lectureUrl
  }
  // next lecture section first item
  item = $('li.next-lecture').parent().parent().next().find('li')[0]
  if (item) {
    return $(item).data().lectureUrl
  }
  return false
}

const getPreviousLectureUrl = function () {
  let item = $('li.next-lecture').prev().data()

  if (item) {
    return item.lectureUrl
  }
  // next lecture section first item
  item = $('li.next-lecture').parent().parent().prev().find('li').last()
  if (item) {
    return $(item).data().lectureUrl
  }
  return location.pathname
}

const isLoggedInOwner = function () {
  const data = document.getElementById('fedora-data')
  const roleAttribute = data && data.dataset && data.dataset.role
  const previewAttribute = data && data.dataset && data.dataset.preview
  return roleAttribute === 'owner' && previewAttribute === 'logged_in'
}

const setupCourseComplianceLecturePage = function () {
  if (
    $('#lecture-completion-data').data().validForCompletion ||
    isLoggedInOwner()
  ) {
    $('#lecture_complete_button').removeClass('disabled')
  } else {
    $('#lecture_complete_button').addClass('disabled')
  }
}

const setupCourseComplianceSidebar = function () {
  ;($('.course-section') as any).each(() => {
    let inProgressItems
    const items = $(this).find('li[data-lecture-id]')
    const completedItems = $(this).find('li.unlocked-lecture')
    if (
      $('#lecture-completion-data') &&
      lectureShowGuard(
        lectureShowGuard($('#lecture-completion-data'), (x1) => x1.data()),
        (x) => x.lastLectureId
      )
    ) {
      inProgressItems = $(this).find(
        `li[data-lecture-id=${
          $('#lecture-completion-data').data().lastLectureId
        }]`
      )
    }
    if (
      inProgressItems &&
      inProgressItems.length > 0 &&
      !$(this).find('.section-title').data().daysUntilDripped
    ) {
      $(inProgressItems[0]).addClass('current-item')
    }
    if (completedItems.length < items.length) {
      $(this).addClass('progress-locked')
      if (
        completedItems.length > 0 ||
        $(this).find('.current-item').length > 0
      ) {
        return $(this).find('.section-lock').addClass('hidden')
      }
    } else {
      return $(this).removeClass('course-section-locked')
    }

    return undefined
  })
}

const courseComplianceEnabled = () =>
  (window as any).fedora_user.accessibleFeatures.indexOf(
    'course_compliance'
  ) !== -1

const lectureOrderEnforced = () =>
  lectureShowGuard(
    lectureShowGuard(
      typeof (window as any).fedora_user !== 'undefined' &&
        (window as any).fedora_user !== null
        ? (window as any).fedora_user.courseData
        : undefined,
      (x1) => x1.is_lecture_order_enforced
    ),
    (x) => x.toString()
  ) === 'true'

const lectureShowReady = function () {
  if (
    !location.pathname.indexOf('/lectures/') &&
    !location.pathname.indexOf('/comments/')
  ) {
    return undefined
  }
  // iterate over completed lectures to update CSS of sidebar items (complete vs incomplete)
  lectureShowGuard((window as any).currentUser(), (x) =>
    x.completedLectureIds.forEach((lectureId) =>
      $(`.section-item[data-lecture-id="${lectureId}"]`).addClass(
        'unlocked-lecture'
      )
    )
  )

  if (courseComplianceEnabled() && lectureOrderEnforced()) {
    setupCourseComplianceSidebar()
  }

  ;($('.section-item[data-completed]') as any).each(function () {
    return $(this)
      .addClass('completed')
      .removeClass('incomplete')
      .removeClass('next-lecture')
  })

  if (onLecturePage()) {
    setUpLecturePage()

    // check to see if "Previous Button" should be hidden
    renderPreviousButton()

    $('#lecture_complete_button').click((evt) => {
      if (evt.currentTarget.className.indexOf('disabled') === -1) {
        evt.preventDefault()
        const nextLectureUrl = getNextLectureUrl()

        return (markAsComplete() as any).then((data) => {
          if (data !== false) {
            if (nextLectureUrl) {
              return handleLectureLinkClickEvent(evt, nextLectureUrl)
            }
            if (data.url) {
              return (document.location.href = data.url)
            }
            return (document.location.href = `${coursePath()}?completed_lecture=true`)
          }
        })
      }
    })

    $('#lecture_previous_button').click((evt) => {
      if (evt.currentTarget.className.indexOf('disabled') === -1) {
        const previousLectureUrl = getPreviousLectureUrl()
        evt.preventDefault()
        if (previousLectureUrl) {
          return (handleLectureLinkClickEvent(
            evt,
            previousLectureUrl
          ) as any).then(() => setLoadingUrl(''))
        }
      }
    })

    $('.item').click((evt) =>
      (handleLectureLinkClickEvent(
        evt,
        (evt.currentTarget as any).href
      ) as any).then(() => setLoadingUrl(''))
    )
  }
}

let renderPreviousButton = function () {
  // if first lecture is selected, hide "Previous Lecture" button
  // yes, one day we will not have to rely on the DOM to store this state
  if ($('.section-item').first().hasClass('next-lecture')) {
    return $('#lecture_previous_button').hide()
  }
  return $('#lecture_previous_button').show()
}

const updatePDFViewerHeight = function () {
  if ($('.pdf-viewer').length > 0) {
    $('.pdf-viewer').height(($(window) as any).height() - 200)
  }
}

const setupLastLectureButton = function () {
  if (
    lectureShowGuard((window as any).currentUser(), (x) => x.id) &&
    lectureShowGuard((window as any).currentUser(), (x1) =>
      x1.courseIds.indexOf((window as any).fedora_user.id)
    ) !== -1
  ) {
    return $('#last_lecture_button').attr(
      'href',
      $('#lecture-completion-data').data().lastLectureUrl
    )
  }

  return undefined
}

let setUpLecturePage = function () {
  ;(window as any).lecture_data = lectureData()
  ;(window as any).Wistia.reinitialize()
  updateSideBarCompletions()
  updateLectureData()
  setupComments()
  setupLastLectureButton()
  $('.course-sidebar').scroll(() => stickyProgressBar())
}

;(window as any).updateDisqus = function () {
  if (!onBlogPage()) {
    ;(window as any).updateCurrentLectureHighlight()
  }
  const disqusData = $('#disqus_data').data()

  if (
    disqusData &&
    typeof (window as any).DISQUS !== 'undefined' &&
    (window as any).DISQUS !== null
  ) {
    return (window as any).DISQUS.reset({
      reload: true,
      config() {
        this.page.url = disqusData.url === '' ? undefined : disqusData.url
        this.page.identifier =
          disqusData.identifier === '' ? undefined : disqusData.identifier
      },
    })
  }
  try {
    return (window as any).DISQUS.reset
  } catch (error) {}
}

let completionUrl = () => `${(window as any).lecture_data.lectureUrl}/complete`

const postCompletionStatus = () =>
  $.ajax({
    url: completionUrl(),
    data: {},
    method: 'POST',
    headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
  })

let percentComplete = () =>
  (
    ($('.section-item.completed').length / $('.section-item').length) *
    100
  ).toFixed()

let updateCompletion = function (percent) {
  $('.progressbar-fill').attr('style', `min-width:${percent}%;`)
  return $('.percentage').text(`${percent}%`)
}

let updateLectureData = function () {
  ;(window as any).updateCurrentLectureHighlight()
  updateSideBarCompletions()
  if (location.pathname.indexOf('/comments/') === -1) {
    const state_object = { id: lectureData().lectureId, name: 'lecture' }
    history.pushState(
      state_object,
      '',
      state_object.id.toString() + window.location.hash
    )
  }
  updatePDFViewerHeight()
  collapseResponsiveSidebar()
  if (
    typeof (window as any).fedora_user !== 'undefined' &&
    (window as any).fedora_user !== null
      ? (window as any).fedora_user.dripEnabled
      : undefined
  ) {
    return (window as any).setupDrip()
  }
}

const lectureSidebarLink = (id) => $(`#sidebar_link_${id}`)

let collapseResponsiveSidebar = function () {
  $('.nav-icon-list').addClass('collapsed')
  return $('.course-sidebar').attr('aria-expanded', 'false').removeClass('in')
}

const lectureSidebarSection = (id) => $(`#sidebar_link_${id}`).parent()

const markLectureAsCompleteInSidebar = (id) =>
  lectureSidebarSection(id).addClass('completed').removeClass('incomplete')

let markAsCompleteInSidebar = () =>
  markLectureAsCompleteInSidebar(lectureData().lectureId)

let updateSideBarCompletions = () =>
  (window as any)
    .currentUser()
    .completedLectureIds.forEach((id) => markLectureAsCompleteInSidebar(id))

const lectureSidebarHeaderHeight = () =>
  // tslint:disable-next-line:radix
  parseInt(`${($('.course-progress.lecture-page') as any).outerHeight() + 5}`)

;(window as any).updateCurrentLectureHighlight = function () {
  $('.section-item').removeClass('next-lecture')
  setLabelsForCompleted()
  ;(window as any).lecture_data = lectureData()
  lectureSidebarSection((window as any).lecture_data.lectureId).addClass(
    'next-lecture'
  )
  setLabelForCurrent()
  const myContainer = $('.course-sidebar')
  if ($('.next-lecture').length) {
    const nextLectureTop = ($('.next-lecture') as any).offset().top
    const myContainerTop = (myContainer as any).offset().top
    const myContainerScrollTop = myContainer.scrollTop()
    return myContainer.animate({
      scrollTop:
        nextLectureTop -
        myContainerTop +
        (myContainerScrollTop as any) -
        lectureSidebarHeaderHeight(),
    })
  }

  return undefined
}

let handleLectureLinkClickEvent = function (event, url) {
  if (url) {
    event.preventDefault()
    return getPageWithoutLayout(url).then((response) => {
      const successEvent = new Event('lecture:ajax_success', {
        bubbles: true,
        cancelable: false,
      })
      document.dispatchEvent(successEvent)
    })
  }
  return true
}

let getPageWithoutLayout = function (url) {
  const lectureContentsPromise = $.ajax({
    url: `${url}?layout=false`,
    method: 'GET',
    headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
  })
  lectureContentsPromise.done(updateWithNewLecturePartial)
  return lectureContentsPromise
}

const updatePageTitle = function () {
  const lectureName = $('.lecture-content').find('h2').text().trim()
  const titleParts = document.title.split('|')
  const schoolName = titleParts[1] ? ` | ${titleParts[1].trim()}` : ''
  const pageTitle = `${lectureName}${schoolName}`
  document.title = pageTitle
}

let updateWithNewLecturePartial = function (data) {
  if ((window as any).removeWistiaVideos) {
    ;(window as any).removeWistiaVideos()
  }
  $('.lecture-content').replaceWith(data)
  $('html, body').animate({ scrollTop: 0 }, 'fast')
  $('html, .lecture-page-layout').animate({ scrollTop: 0 }, 'fast')
  updateLectureData()
  setupComments()
  ;($('#comments_wrapper') as any).innerHTML = ''
  ;(window as any).updateCurrentLectureHighlight()
  updatePageTitle()
  setupLastLectureButton()
  $(document).trigger('lecture:change')
  document.dispatchEvent(new Event('lecture:change'))
  // check to see if "Previous Button" should be hidden
  renderPreviousButton()
  if (courseComplianceEnabled() && lectureOrderEnforced()) {
    setupCourseComplianceLecturePage()
    setupCourseComplianceSidebar()
  }
}

let setupComments = function () {
  ;(window as any).resetCommentData()
  ;(window as any).setCommentData()
  ;(window as any).loadCommentsPage(1)
  return (window as any).setupCommentHandlers()
}

// Detection Methods
let onBlogPage = () => document.URL.indexOf('/blog') !== -1

let onLecturePage = () => document.URL.indexOf('/lectures/') !== -1

// Sticky progress bar in the lecture sidebar
let stickyProgressBar = function () {
  // tslint:disable-next-line:radix
  const stickySidebarPadding = parseInt(
    ($('.course-progress.lecture-page') as any).outerHeight() + 5
  )
  const sidebarMinimumScroll =
    ($('.course-sidebar h2').height() as any) + 25 + $(window).height()

  if (
    ($('.course-sidebar') as any).scrollTop() + $(window).height() >
    sidebarMinimumScroll
  ) {
    $('.course-progress.lecture-page')
      .removeClass('is-at-top')
      .addClass('sticky')
    $('.course-sidebar.lecture-page h2').attr(
      'style',
      `padding-bottom: ${stickySidebarPadding}px`
    )
  } else {
    $('.course-sidebar.lecture-page h2').attr('style', 'padding-bottom: 0px')
    $('.course-progress.lecture-page')
      .addClass('is-at-top')
      .removeClass('sticky')
  }
}

let setLabelsForCompleted = function () {
  $('.section-item .status-container').removeAttr('aria-label')
  return $('.section-item.completed .status-container').attr(
    'aria-label',
    'Completed item'
  )
}

let setLabelForCurrent = () =>
  $('.section-item.next-lecture .status-container').attr(
    'aria-label',
    'Current item'
  )

$(document).on('ready page:load', lectureShowReady)

function lectureShowGuard(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined
}
